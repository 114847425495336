<template>
  <b-card>
    <TablePagination
      v-model="items"
      :url="`/admin/users/pack_of_photos/find_per_user_id/${userData.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:image="data">
        <img
          class="rounded-lg"
          :src="`${$api_host}storage/assets/${data.items.option.image}`"
          width="35px"
        >
      </template>

      <template v-slot:option="data">
        {{ data.items.option.name }}
      </template>

      <template v-slot:actions="data">
        <b-button
          class="mr-1"
          variant="relief-primary"
          @click.stop="openLink(data.items.link)"
        >
          Baixar
        </b-button>

        <b-button
          size="sm"
          variant="relief-danger"
          :disabled="data.items.order === null || data.items.order.refunded === 1"
          @click.stop="refund(data.items.id)"
        >
          <span v-if="!loading"> Reembolsar </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'image',
        label: 'Produto'
      },
      {
        key: 'option',
        label: 'Pacote'
      },
      {
        key: 'actions',
        label: 'Baixar'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    openLink (link) {
      window.open(link)
    },
    async refund (id) {
      this.loading = true
      const url = `/admin/users/pack_of_photos/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).order.refunded = 1
          this.loading = false
        })
    }
  }
}
</script>
