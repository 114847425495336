<template>
  <b-card>
    <TablePagination
      v-model="items"
      :url="`/admin/users/controle_infinity/find_per_user_id/${userData.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:image="">
        <img
          class="rounded-lg"
          :src="`${$api_host}storage/assets/robot.png`"
          width="35px"
        >
      </template>

      <template v-slot:name="data">
        {{ data.items.plan_name }}
      </template>

      <template v-slot:price="data">
        {{ data.items.price | currency }}
      </template>

      <template v-slot:purchased_at="data">
        {{ data.items.created_at | date }}
      </template>

      <template v-slot:actions="data">
        <b-button
          size="sm"
          variant="relief-danger"
          :disabled="data.items.order === null || data.items.order.refunded === 1"
          @click.stop="refund(data.items.id)"
        >
          <span v-if="!loading"> Reembolsar </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import UserControleInfinityMixin from './mixins/UserControleInfinityMixin'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  mixins: [UserControleInfinityMixin],
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'image',
        label: 'Produto'
      },
      {
        key: 'name',
        label: 'Plano'
      },
      {
        key: 'price',
        label: 'Valor'
      },
      {
        key: 'purchased_at',
        label: 'Comprado em'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  })
}
</script>
