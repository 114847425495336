<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="2px"
    rounded="sm"
  >
    <div v-if="!loading">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined && loading === false"
      >
        <h4 class="alert-heading">
          Não foi possível encontrar o usuário.
        </h4>
      </b-alert>

      <template>
        <b-row class="match-height">
          <b-col
            cols="12"
            xl="8"
            md="7"
            lg="8"
          >
            <user-view-user-info-card
              :user-data="userData"
              @edit="editModal = true"
              @reload="userUpdated()"
            />
          </b-col>
          <b-col
            cols="12"
            xl="4"
            md="5"
            lg="4"
          >
            <b-row>
              <b-col cols="12">
                <b-card>
                  <b-row style="margin-top: 5px; margin-bottom: 5px;">
                    <b-col cols="7">
                      <span class="font-weight-bolder">Verificador Infinity</span> <br>
                      <span style="font-size: 13px; font-weight: 450">
                        <span style="color: #e9ff36">{{ userData.verificador_infinity.days_left }}</span>
                        Dias Restantes
                      </span>
                    </b-col>
                    <b-col cols="5">
                      <b-button
                        variant="relief-success"
                        class="btn-icon float-center"
                        :disabled="buttonBlock"
                        @click.stop="addDaysToVerificadorInfinity()"
                      >
                        <feather-icon
                          v-if="!buttonBlock"
                          icon="PlusCircleIcon"
                          size="18"
                        />
                        <b-spinner
                          v-else
                          small
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card>
                  <b-row style="margin-top: 5px; margin-bottom: 5px;">
                    <b-col cols="7">
                      <span class="font-weight-bolder">Controle Infinity</span> <br>
                      <span style="font-size: 13px; font-weight: 450">
                        <span style="color: #e9ff36">{{ userData.controle_infinity.days_left }}</span>
                        Dias Restantes
                      </span>
                    </b-col>
                    <b-col cols="5">
                      <b-button
                        variant="relief-success"
                        class="btn-icon float-center"
                        :disabled="buttonBlock"
                        @click.stop="addDaysToControleInfinity()"
                      >
                        <feather-icon
                          v-if="!buttonBlock"
                          icon="PlusCircleIcon"
                          size="18"
                        />
                        <b-spinner
                          v-else
                          small
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            :lg="colapseRecentPurchases ? 4 : 12"
          >
            <b-card>
              <b-card-title>
                Compras recentes

                <b-button
                  variant="relief-primary"
                  class="btn-icon float-right"
                  @click.stop="colapseRecentPurchases = !colapseRecentPurchases"
                >
                  <feather-icon
                    :icon="colapseRecentPurchases ? 'PlusSquareIcon' : 'MinusSquareIcon'"
                    size="14"
                  />
                </b-button>
              </b-card-title>
              <RecentPurchases v-show="!colapseRecentPurchases" />
            </b-card>
          </b-col>

          <b-col
            cols="12"
            :lg="colapseAffiliates ? 4 : 12"
          >
            <b-card>
              <b-card-title>
                Afiliados ({{ userData.affiliates.affiliates.length }})

                <b-button
                  variant="relief-primary"
                  class="btn-icon float-right"
                  @click.stop="colapseAffiliates = !colapseAffiliates"
                >
                  <feather-icon
                    :icon="colapseAffiliates ? 'PlusSquareIcon' : 'MinusSquareIcon'"
                    size="14"
                  />
                </b-button>
              </b-card-title>
              <Affiliates
                v-if="!colapseAffiliates"
                :user-data="userData"
                @reload="userUpdated()"
              />
            </b-card>
          </b-col>

          <b-col
            cols="12"
            :lg="colapseRecentBalanceHistory ? 4 : 12"
          >
            <b-card>
              <b-card-title>
                Adições de Saldo

                <b-button
                  variant="relief-primary"
                  class="btn-icon float-right"
                  @click.stop="colapseRecentBalanceHistory = !colapseRecentBalanceHistory"
                >
                  <feather-icon
                    :icon="colapseRecentBalanceHistory ? 'PlusSquareIcon' : 'MinusSquareIcon'"
                    size="14"
                  />
                </b-button>
              </b-card-title>
              <RecentBalanceHistory v-show="!colapseRecentBalanceHistory" />
            </b-card>
          </b-col>
        </b-row>

        <UserPurchaseHandler :user-data="userData" />

        <b-row>
          <b-col cols="12">
            <b-card>
              IP Cadastrado: {{ userData.current_ip }}
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card>
              Conta criada em: {{ userData.created_at | date }}
            </b-card>
          </b-col>
        </b-row>

        <Edit
          v-model="editModal"
          :data="userData"
          @updated="userUpdated()"
        />
      </template>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BSpinner, BRow, BCol, BTab, BTabs, BCard, BCardTitle, BButton, BAlert, BLink
} from 'bootstrap-vue'
import moment from 'moment'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserPurchaseHandler from './UserPurchaseHandler/Index.vue'
import RecentPurchases from './RecentPurchases.vue'
import Affiliates from './Affiliates.vue'
import RecentBalanceHistory from './RecentBalanceHistory.vue'
import Edit from './Edit.vue'

export default {
  components: {
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BCardTitle,
    BButton,
    BAlert,
    BLink,
    UserViewUserInfoCard,
    UserPurchaseHandler,
    RecentPurchases,
    Affiliates,
    RecentBalanceHistory,
    Edit
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    }
  },
  data: () => ({
    loading: true,
    buttonBlock: false,
    userData: undefined,
    editModal: false,
    colapseRecentPurchases: true,
    colapseAffiliates: true,
    colapseRecentBalanceHistory: true
  }),
  mounted () {
    this.getUserData()
  },
  methods: {
    userUpdated () {
      this.loading = true
      this.getUserData()
    },
    async getUserData () {
      const userID = this.$router.currentRoute.params.id
      const url = `/admin/users/${userID}`

      await this.$http.get(url)
        .then(res => {
          if (Object.values(res.data).length > 0) {
            this.userData = res.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async addDaysToVerificadorInfinity () {
      const url = `/admin/verificador_infinity/add_days/${this.userData.id}/1`
      this.buttonBlock = true

      await this.$http.get(url)
        .finally(() => {
          this.buttonBlock = false
          this.userData.verificador_infinity.days_left++
        })
    },
    async addDaysToControleInfinity () {
      const url = `/admin/controle_infinity/add_days/${this.userData.id}/1`
      this.buttonBlock = true

      await this.$http.get(url)
        .finally(() => {
          this.buttonBlock = false
          this.userData.controle_infinity.days_left++
        })
    }
  }
}
</script>
