<template>
  <b-card>
    <TablePagination
      v-model="items"
      :url="`/admin/users/vps_accounts/find_per_user_id/${userData.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:image="data">
        <img
          class="rounded-lg"
          :src="`${$api_host}storage/assets/${data.items.option.image}`"
          width="35px"
        >
      </template>

      <template v-slot:ip="data">
        <b-button
          v-clipboard:copy="data.items.ip"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.ip }}
        </b-button>
      </template>

      <template v-slot:username="data">
        <b-button
          v-clipboard:copy="data.items.username"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.username }}
        </b-button>
      </template>

      <template v-slot:password="data">
        <b-button
          v-clipboard:copy="data.items.password"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.password }}
        </b-button>
      </template>

      <template v-slot:contact="data">
        <img
          class="rounded-lg"
          :src="`${$api_host}/storage/assets/whatsapp.png`"
          width="35px"
          @click.stop="openSupport(data.items)"
        >
      </template>

      <template v-slot:plan_name="data">
        {{ data.items.plan_name }}
      </template>

      <template v-slot:status="data">
        <b-button
          v-if="!data.items.status"
          v-b-tooltip.click.v-primary
          :title="statusMessage(data.items)"
          size="sm"
          variant="relief-success"
        >
          {{ statusMessage(data.items) }}
        </b-button>
        <b-button
          v-else
          v-b-tooltip.click.v-primary
          :title="statusMessage(data.items)"
          size="sm"
          variant="relief-danger"
        >
          {{ statusMessage(data.items) }}
        </b-button>
      </template>

      <template v-slot:created_at="data">
        {{ data.items.created_at | date }}
      </template>

      <template v-slot:actions="data">
        <b-button
          size="sm"
          variant="relief-danger"
          :disabled="data.items.status === 'Reembolsado'"
          @click.stop="refund(data.items.id)"
        >
          <span v-if="!loading"> Reembolsar </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BSpinner, VBTooltip
} from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import UserVPSAccountsMixin from './mixins/UserVPSAccountsMixin'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  mixins: [UserVPSAccountsMixin],
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'ip',
        label: 'ip'
      },
      {
        key: 'username',
        label: 'Usuário'
      },
      {
        key: 'password',
        label: 'Senha'
      },
      {
        key: 'plan_name',
        label: 'Tipo de conta'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'created_at',
        label: 'Data de Compra'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  })
}
</script>
