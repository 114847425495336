<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="!(userData.personal_data.photo === null || userData.personal_data.photo === '')"
            :src="`${$api_host}${userData.personal_data.photo}`"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :src="require('@/assets/images/avatars/default.jpeg')"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.personal_data.full_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click.stop="$emit('edit')"
              >
                Editar
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Deletar
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.balance.amount | currency }}
              </h5>
              <small>Saldo atual</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.balance.total_invested | currency }}
              </h5>
              <small>Saldo gasto</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="ml-1">
              <b-button
                class="mr-1"
                size="sm"
                variant="relief-primary"
                @click.stop="logInPerId(userData.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="ml-2 mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Cargo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.roles[0].name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Saldo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.balance.amount | currency }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Whatsapp</span>
            </th>
            <td>
              {{ userData.personal_data.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="TrelloIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nível</span>
            </th>
            <td>
              {{ userData.discount.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="TrelloIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Desconto</span>
            </th>
            <td>
              {{ userData.discount.discount }}%
            </td>
          </tr>
          <tr>
            <th class="pb-100">
              <feather-icon
                icon="TrelloIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Indicado por</span>
            </th>
            <td>
              <span v-if="userData.referer !== null">
                <b-button
                  variant="relief-dark"
                  size="sm"
                  @click.stop="viewUserId(userData.referer.id)"
                >
                  ID: {{ userData.referer.id }}
                </b-button>
              </span>
              <span v-else>
                Ninguém
              </span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol
} from 'bootstrap-vue'
import logInPerId from '@/mixins/logInPerId'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar
  },
  filters: {
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  mixins: [logInPerId],
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  methods: {
    viewUserId (id) {
      this.$router.push({ name: 'Visualizar Usuário (Admin)', params: { id } })
        .then(() => this.$emit('reload'))
    }
  }
}
</script>
