<template>
  <b-card>
    <TablePagination
      v-model="items"
      :url="`/admin/users/sn_actions/find_per_user_id/${userData.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:image="data">
        <img
          class="rounded-lg"
          :src="`${$api_host}storage/assets/${data.items.sn_action.option.image}`"
          width="35px"
        >
      </template>

      <template v-slot:name="data">
        <span v-html="data.items.name" />
      </template>

      <template v-slot:profile="data">
        {{ data.items.api_profile }}
      </template>

      <template v-slot:quantity="data">
        {{ data.items.quantity }}
      </template>

      <template v-slot:value="data">
        <b-button
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.value | currency }}
        </b-button>
      </template>

      <template v-slot:status="data">
        <b-button
          size="sm"
          :variant="getStatus(data.items.status).color"
        >
          {{ getStatus(data.items.status).message }}
        </b-button>
      </template>

      <template v-slot:created_at="data">
        <b-button
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.created_at | date }}
        </b-button>
      </template>

      <template v-slot:actions="data">
        <b-button
          size="sm"
          variant="relief-danger"
          :disabled="data.items.status === 4"
          @click.stop="refund(data.items.id)"
        >
          <span v-if="!loading"> Reembolsar </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import UserSNActionMixin from './mixins/UserSNActionsMixin'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  mixins: [UserSNActionMixin],
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      // {
      //   key: 'image',
      //   label: 'Produto'
      // },
      {
        key: 'actions',
        label: 'Ações'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'profile',
        label: 'Perfil'
      },
      {
        key: 'quantity',
        label: 'Quantidade'
      },
      {
        key: 'value',
        label: 'valor',
        sortable: true
      },
      {
        key: 'created_at',
        label: 'data'
      }
    ]
  })
}
</script>
