import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    },
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    getStatus (item) {
      const timeExpired = moment().isAfter(moment(item.created_at).add(item.time_to_cancel, 'seconds'))
      const timeExpiredAfter = moment(moment(item.updated_at)).isAfter(moment(item.created_at).add(item.time_to_cancel, 'seconds'))
      let response = {
        color: '',
        message: ''
      }

      if (item.status === 1 && item.code === null && !timeExpired) {
        response = {
          color: 'relief-primary',
          message: 'Aguardando SMS...'
        }
      }

      if (item.status === 2 && item.code === null && !timeExpired) {
        response = {
          color: 'relief-warning',
          message: 'Reenviando SMS...'
        }
      }

      if (item.status === 1 && item.code === null && timeExpired) {
        response = {
          color: 'relief-danger',
          message: 'Tempo Esgotado'
        }
      }

      if (item.status === 3 && item.code !== null) {
        response = {
          color: 'relief-success',
          message: 'Concluído'
        }
      }

      if (item.status === 4 && item.code === null && timeExpiredAfter) {
        response = {
          color: 'relief-danger',
          message: 'Tempo esgotado'
        }
      }

      if (item.status === 4 && item.code !== null) {
        response = {
          color: 'relief-success',
          message: 'Finalizado'
        }
      }

      if (item.status === 5 && item.code === null) {
        response = {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }

      if (item.status === 6) {
        response = {
          color: 'relief-warning',
          message: 'Reembolsado'
        }
      }

      return response
    },
    async refund (id) {
      this.loading = true
      const url = `/admin/users/sms/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).status = 6
          this.loading = false
        })
    }
  }
}
