<template>
  <section>
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="SMS">
        <UserSMS :user-data="userData" />
      </b-tab>

      <b-tab title="Seguidores">
        <UserSNActions :user-data="userData" />
      </b-tab>

      <b-tab title="Contas">
        <UserSNAccounts :user-data="userData" />
      </b-tab>

      <b-tab title="Pack de Fotos">
        <UserPackOfPhotos :user-data="userData" />
      </b-tab>

      <b-tab title="VPS">
        <UserVPSAccounts :user-data="userData" />
      </b-tab>

      <b-tab title="Verificador Infinity">
        <UserVerificadorInfinity :user-data="userData" />
      </b-tab>

      <b-tab title="Controle Infinity">
        <UserControleInfinity :user-data="userData" />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserSMS from './UserSMS.vue'
import UserSNActions from './UserSNActions.vue'
import UserSNAccounts from './UserSNAccounts.vue'
import UserPackOfPhotos from './UserPackOfPhotos.vue'
import UserVPSAccounts from './UserVPSAccounts.vue'
import UserVerificadorInfinity from './UserVerificadorInfinity.vue'
import UserControleInfinity from './UserControleInfinity.vue'

export default {
  components: {
    BTabs,
    BTab,
    UserSMS,
    UserSNActions,
    UserSNAccounts,
    UserPackOfPhotos,
    UserVPSAccounts,
    UserVerificadorInfinity,
    UserControleInfinity
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  }
}
</script>
