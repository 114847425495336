import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    }
  },
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    async refund (id) {
      this.loading = true
      const url = `/admin/users/sn_accounts/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).status = 'Reembolsado'
          this.loading = false
        })
    }
  }
}
