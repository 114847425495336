<template>
  <b-table
    v-if="table"
    striped
    hover
    responsive
    :per-page="100"
    :current-page="1"
    :items="items"
    :fields="fields"
  >
    <template #cell(name)="data">
      <!-- <img
        class="rounded-lg"
        :src="`${$api_host}/storage/assets/${data.item.image}`"
        width="35px"
      > &nbsp; -->
      <span v-html="data.item.name" />
    </template>

    <template #cell(amount)="data">
      <span v-if="data.item.type === 2">
        {{ data.item.amount | currency2 }}
      </span>
      <span v-else>
        {{ data.item.amount | currency }}
      </span>
    </template>

    <template #cell(created_at)="data">
      {{ data.item.created_at | date }}
    </template>

    <template #cell(status)="data">
      <b-button
        size="sm"
        :variant="getStatus(data.item.status).color"
      >
        {{ getStatus(data.item.status).message }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BTable, BButton
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 4)
    },
    currency2 (val) {
      return currencyPrecision(val, 2, 6)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    table: false,
    items: [],
    fields: [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'amount',
        label: 'valor'
      },
      {
        key: 'created_at',
        label: 'Data de realização'
      },
      {
        key: 'status',
        label: 'status'
      }
    ]
  }),
  async mounted () {
    await this.getLastPurchase()
  },
  methods: {
    async getLastPurchase () {
      const userID = this.$router.currentRoute.params.id
      const url = `/admin/orders/get_last_five_purchases_of_user/${userID}`

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.table = true
        })
    },
    getStatus (status) {
      if (status === 1) {
        return {
          color: 'relief-primary',
          message: 'Pendente'
        }
      }

      if (status === 2) {
        return {
          color: 'relief-success',
          message: 'Aprovado'
        }
      }

      if (status === 3) {
        return {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }
    }
  }
}
</script>
