<template>
  <section>
    <div>
      <span>Bloqueado: {{ userData.affiliates.commission_to_receive | currency }}</span> <br>
      <span>Disponível: {{ userData.affiliates.total_commission_available | currency }}</span> <br>
      <span>Saque pendente: {{ userData.affiliates.waiting_to_withdraw | currency }}</span> <br>
      <span>Total de Saques: {{ userData.affiliates.total_withdraw | currency }}</span> <br> <br>
    </div>

    <b-table
      v-if="table"
      striped
      hover
      responsive
      :per-page="1000"
      :current-page="1"
      :items="items"
      :fields="fields"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at | date }}
      </template>

      <template #cell(actions)="data">
        <b-button
          size="sm"
          variant="relief-primary"
          @click.stop="viewUserId(data.item.id)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
  </section>
</template>

<script>
import {
  BTable, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BTable, BButton
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    table: false,
    items: [],
    fields: [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'ip',
        label: 'ip'
      },
      {
        key: 'created_at',
        label: 'Cadastrado em'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  async mounted () {
    this.items = this.userData.affiliates.affiliates
    this.table = true
  },
  methods: {
    viewUserId (id) {
      this.$router.push({ name: 'Visualizar Usuário (Admin)', params: { id } })
        .then(() => this.$emit('reload'))
    }
  }
}
</script>
