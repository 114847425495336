import moment from 'moment'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    },
    currency (val) {
      return currencyPrecision(val, 2, 6)
    }
  },
  methods: {
    getStatus (status) {
      if (status === 0) {
        return {
          color: 'relief-dark',
          message: 'Em fila'
        }
      }

      if (status === 1) {
        return {
          color: 'relief-primary',
          message: 'Pendente'
        }
      }

      if (status === 2) {
        return {
          color: 'relief-warning',
          message: 'Em progresso'
        }
      }

      if (status === 3) {
        return {
          color: 'relief-success',
          message: 'Finalizado'
        }
      }

      if (status === 4) {
        return {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }

      if (status === 5) {
        return {
          color: 'relief-warning',
          message: 'Parcial'
        }
      }

      if (status === 6) {
        return {
          color: 'relief-danger',
          message: 'Sem Saldo'
        }
      }
    },
    async refund (id) {
      this.loading = true
      const url = `/admin/users/sn_actions/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).status = 4
          this.loading = false
        })
    }
  }
}
