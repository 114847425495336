import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    },
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  methods: {
    async refund (id) {
      this.loading = true
      const url = `/admin/controle_infinity/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).order.refunded = 1
          this.loading = false
        })
    }
  }
}
