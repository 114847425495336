<template>
  <b-table
    v-if="table"
    striped
    hover
    responsive
    :per-page="100"
    :current-page="1"
    :items="items"
    :fields="fields"
  >
    <template #cell(amount)="data">
      {{ data.item.amount | currency }}
    </template>

    <template #cell(action)="data">
      <span v-if="data.item.action === 'addition'">Saldo Adicionado</span>
      <span v-if="data.item.action === 'subtraction'">Saldo Retirado</span>
    </template>

    <template #cell(type)="data">
      <span v-if="data.item.type === 'Manually'">Manual</span>
      <span v-else>{{ data.item.type }}</span>
      <span v-if="data.item.old_history"> &nbsp;(Histórico Antigo) </span>
    </template>

    <template #cell(created_at)="data">
      {{ data.item.created_at | date }}
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BTable, BButton
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 4)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    table: false,
    items: [],
    fields: [
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'action',
        label: 'Ação'
      },
      {
        key: 'type',
        label: 'Tipo'
      },
      {
        key: 'created_at',
        label: 'Data de realização'
      }
    ]
  }),
  async mounted () {
    await this.getLastBalanceHistory()
  },
  methods: {
    async getLastBalanceHistory () {
      const userID = this.$router.currentRoute.params.id
      const url = `/admin/users/balance/last_five_balance_history/${userID}`

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.table = true
        })
    }
  }
}
</script>
