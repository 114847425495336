<template>
  <b-card>
    <TablePagination
      v-model="items"
      :url="`/admin/users/sn_accounts/find_per_user_id/${userData.id}`"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:image="data">
        <img
          class="rounded-lg"
          :src="`${$api_host}storage/assets/${data.items.option.image}`"
          width="35px"
        >
      </template>

      <template v-slot:username="data">
        <b-button
          v-clipboard:copy="data.items.username"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.username }}
        </b-button>
      </template>

      <template v-slot:password="data">
        <b-button
          v-clipboard:copy="data.items.password"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.password }}
        </b-button>
      </template>

      <template v-slot:email="data">
        <b-button
          v-clipboard:copy="data.items.email"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.email }}
        </b-button>
      </template>

      <template v-slot:email_password="data">
        <b-button
          v-clipboard:copy="data.items.email_password"
          v-clipboard:success="onCopy"
          size="sm"
          variant="relief-primary"
        >
          {{ data.items.email_password }}
        </b-button>
      </template>

      <template v-slot:option="data">
        {{ data.items.option.name }}
      </template>

      <template v-slot:created_at="data">
        {{ data.items.created_at | date }}
      </template>

      <template v-slot:actions="data">
        <b-button
          size="sm"
          variant="relief-danger"
          :disabled="data.items.status === 'Reembolsado'"
          @click.stop="refund(data.items.id)"
        >
          <span v-if="!loading"> Reembolsar </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import UserSNAccountsMixin from './mixins/UserSNAccountsMixin'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  mixins: [UserSNAccountsMixin],
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'image',
        label: 'Produto'
      },
      {
        key: 'username',
        label: 'Usuário'
      },
      {
        key: 'password',
        label: 'senha'
      },
      {
        key: 'option',
        label: 'Tipo de conta'
      },
      {
        key: 'created_at',
        label: 'Comprado em'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  })
}
</script>
