import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    }
  },
  methods: {
    statusMessage (item) {
      const days = parseInt(moment(item.end_time).diff(moment(), 'days'))

      if (days < 0) {
        return `Expirado há ${Math.abs(days)} dia(s)`
      }

      if (days === 0) {
        return 'Expira hoje'
      }

      if (days > 0) {
        return `Expira em ${days} dia(s)`
      }
    },
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    openSupport (item) {
      const contactSupport = item.support_contact
      const wpLink = `https://api.whatsapp.com/send?phone=55${contactSupport}`
      const text = `&text=%F0%9F%92%BB%20%7C%20*Ol%C3%A1!%20Tenho%20uma%20d%C3%BAvida%20/%20quero%20ativar%20a%20minha%20VPS!*%20%0A%F0%9F%8C%90%20%7C%20*Compra%20feita%20atrav%C3%A9s%20do%20site:%20contasinfinity.com.br*%0A%0A%E2%84%B9%EF%B8%8F%20%7C%20*Nome%20de%20usu%C3%A1rio%20da%20minha%20VPS%20%C3%A9*%3A%20${item.username}`

      window.open(wpLink + text)
    },
    async refund (id) {
      this.loading = true
      const url = `/admin/users/vps_accounts/refund/${id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(item => item.id === id).status = 'Reembolsado'
          this.loading = false
        })
    }
  }
}
